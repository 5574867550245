import {
  MatButtonModule,
} from '@angular/material/button';
import {
  MatStepperModule,
} from '@angular/material/stepper';
import {
  MatInputModule,
} from '@angular/material/input';
import {
  MatChipsModule,
} from '@angular/material/chips';
import {
  MatTableModule,
} from '@angular/material/table';
import {
  MatTabsModule,
} from '@angular/material/tabs';
import {
  MatPaginatorModule,
} from '@angular/material/paginator';
import {
  MatSortModule,
} from '@angular/material/sort';
import {
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import {
  MatToolbarModule,
} from '@angular/material/toolbar';
import {
  MatSidenavModule,
} from '@angular/material/sidenav';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatListModule,
} from '@angular/material/list';
import {
  MatGridListModule,
} from '@angular/material/grid-list';
import {
  MatCardModule,
} from '@angular/material/card';
import {
  MatRadioModule
} from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';


const MaterialModules = [
  MatStepperModule,
  MatDividerModule,
  MatInputModule,
  MatChipsModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatExpansionModule,
  MatDialogModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSliderModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatBadgeModule
  // MatSelectCountryModule
];

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BarChartModule, LineChartModule } from '@swimlane/ngx-charts';
// import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

export const imports = [
  ...MaterialModules,
  FormsModule,
  LayoutModule,
  ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'always' }),
  BrowserAnimationsModule,
  NgxDatatableModule,
  NgxSpinnerModule,
  BarChartModule,
  LineChartModule,
];
