// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCGMOACxUFmBfAr9bMNK-VQ4001k7kZL9o",
    authDomain: "parabola-172de.firebaseapp.com",
    projectId: "parabola-172de",
    storageBucket: "parabola-172de.appspot.com",
    messagingSenderId: "377930955801",
    appId: "1:377930955801:web:df7571fdd9da3542377e88",
    measurementId: "G-KDBNZ6VMNB"
  },
  hmr: true,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
