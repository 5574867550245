<div class="container-fluid">
    <div class="row col-12">
        <div class="col-12">
            <h3>{{securitiesAccount && securitiesAccount.accountId || ''}}</h3>

            <mat-form-field class="m-2">
                <mat-label>Profit Target 1 in Percent</mat-label>

                <input matInput [(ngModel)]="profitTarget1" currencyMask [options]="{ prefix: '', min: 0, max: 200.00}"
                    required />
                <span matSuffix>%</span>
                <!-- 
            <mat-hint>This sets a limit price for the positions in your portfolio at 10% profit when you click the Set
                Profit
                Target
                Button for Each Order</mat-hint> -->
            </mat-form-field>
            <button mat-button color="primary" (click)="placeBulkProfitTarget1Order()">
                Bulk Profit Target Limit Order for All Options
            </button>
        </div>
        <div *ngIf="!tdConnected">
            Please login to TD ->
            <a target="_blank" [href]="tdAuthUrl">TD
                Connect</a>
        </div>
        <div *ngIf="securitiesAccount">

            <div *ngFor="let p of positions" class="row col-12">

                <div class="col-6">
                    {{p.instrument.underlyingSymbol}} -
                    {{p.instrument.symbol}}
                    - {{p.instrument.assetType}}
                    - Long Quantity: {{p.longQuantity}}
                    - Short Quantity: {{p.shortQuantity}}
                    - ${{p.averagePrice}}
                    - 10% profit at ${{(p.averagePrice * 0.1) + p.averagePrice | number:'1.2-2'}}
                    <br>
                    <button mat-button color="primary" (click)="placeProfitTarget1Order(p)">
                        Place Profit Target Limit Order
                    </button>
                </div>
                <div class="row col-6">

                    Orders:

                    <div class="col-12" *ngFor="let o of getOrdersForPositions(p.instrument.symbol)">
                        {{o.session}} -
                        {{o.status}} -
                        {{o.orderType}} -
                        {{o.price}}

                        <span *ngFor="let olc of o.orderLegCollection">
                            {{olc.instrument.assetType}} -
                            {{olc.instrument.description}} -
                            {{olc.instruction}}

                            <br>
                        </span>
                        <button mat-button color="primary" (click)="cancelOrderById(o.orderId)">
                            Cancel
                        </button>

                    </div>
                </div>



            </div>

        </div>
    </div>
</div>