import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TdSecuritiesAccount } from 'src/app/_models/td-securities-account';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-functions-test-page',
  templateUrl: './functions-test-page.component.html',
  styleUrls: ['./functions-test-page.component.css']
})
export class FunctionsTestPageComponent implements OnInit {
  positions: any;
  orders: any;
  selectedAccount: any;
  securitiesAccount: TdSecuritiesAccount;
  user: any;
  profitTarget1: any;
  tdConnected: boolean;
  tdAuthUrl: string;
  constructor(
    private cFns: AngularFireFunctions,
    private fire: FirebaseService
  ) { }

  async ngOnInit() {
    console.log()
    this.user = await this.fire.getUserById('vEetGSCBtifTs9MMxmUGs3f1GMQ2').pipe(first()).toPromise();

    this.selectedAccount = this.user.tdaApi.accounts[0];

    this.tdConnected = await this.tdaHeartbeat({ user: this.user, accountId: this.selectedAccount });
    if (this.tdConnected) {
      this.getTdPositions({ user: this.user, accountId: this.selectedAccount });
    } else {
      this.tdAuthUrl = encodeURI(`https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=localhost:5000/dashboard/test&client_id=${this.user.tdaApi.key}`);
    }
  }

  async tdaHeartbeat(data: any) {
    return await this.cFns.httpsCallable('tdaHeartbeat')(data).toPromise().then((heartbeat: any) => {
      return heartbeat;

    }).catch((err) => {
      console.log(err);
      return false;
    });
  }

  async getTdPositions(data: any) {
    return await this.cFns.httpsCallable('getTdPositions')(data).toPromise().then((a: any) => {
      console.log(a);
      this.securitiesAccount = a.securitiesAccount;
      this.positions = this.securitiesAccount.positions;
      this.getTdOpenOrders();

    }).catch((err) => {
      console.log(err);
      return err;
    });
  }

  async getTdOpenOrders() {
    const data = { user: this.user, accountId: this.selectedAccount };
    return await this.cFns.httpsCallable('getTdOpenOrders')(data).toPromise().then((a: any) => {
      console.log(a);
      if (a.length > 0) {
        this.orders = _.filter(a, (o) => o.status !== "CANCELED");
      }
    });
  }

  getOrdersForPositions(symbol: string) {
    return _.filter(this.orders, o => symbol === o.orderLegCollection[0].instrument.symbol);
  }

  async cancelOrderById(orderId: string) {
    const data = { user: this.user, accountId: this.selectedAccount, orderId }
    return await this.cFns.httpsCallable('cancelOrderById')(data).toPromise().then((a: any) => {
      this.getTdOpenOrders();
    });
  }

  async placeProfitTarget1Order(position: any) {
    const quantity = position.longQuantity;
    const symbol = position.instrument.symbol;
    const averagePrice = position.averagePrice;
    // CALCULATE LIMIT PRICE
    const percentTarget = Number((this.profitTarget1 / 100).toFixed(4));
    const price = ((averagePrice * percentTarget) + averagePrice).toFixed(2);
    // console.log(limitPrice);
    const data = { user: this.user, accountId: this.selectedAccount, symbol, quantity, price }
    return await this.cFns.httpsCallable('sendTdSellOrder')(data).toPromise().then((a: any) => {
      console.log('completed');
    });
  }

  async placeBulkProfitTarget1Order() {
    for (let p of this.positions) {
      if (p.instrument.assetType === "OPTION" && p.longQuantity > 0) {
        await this.placeProfitTarget1Order(p);
      }
    }
    await this.getTdOpenOrders();
  }

}