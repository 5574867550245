import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  currentFirestoreUser: any;

  constructor(
    private afs: AngularFirestore
  ) { }


  // GET ADVISOR ITEM BY ID
  getUserById(id: string) {
    const itemDoc = this.afs.doc<any>('users/' + id);
    return itemDoc.valueChanges();
  }

}
