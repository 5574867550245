import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any;
  loginForm: FormGroup;
  errorMessage = '';
  loggingIn = false;


  constructor(
    private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private router: Router,
    // CAUSES COMPONENT ERROR FOR SOME REASON
    // public spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.createForms();

    // FOR WHEN REROUTING ENABLED
    // this.redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
  }

  createForms() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  tryLogin() {
    const { value } = this.loginForm;
    return this.afAuth.signInWithEmailAndPassword(value.email, value.password)
      .then((result) => {
        console.log('result? ', result);
        // this.ngZone.run(() => {
        this.router.navigate(['dashboard']);
        // });
        // this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }
}
