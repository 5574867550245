<div class="row justify-content-center">
  <div class="col-4">
    <form [formGroup]="loginForm">
      <div class="form-group">
        <label>Email address</label>
        <input type="email" formControlName="email" class="form-control">
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" formControlName="password">
        <label class="error">
          {{ errorMessage }}
        </label>
      </div>
      <div class="d-flex justify-content-center">
        <button class="mx-2 my-2" type="submit" mat-flat-button color="primary" (click)="tryLogin()">
          <span [style]="loggingIn ? 'opacity: 0;' : 'opacity: 1;'">Login</span>

          <!-- <ngx-spinner bdColor="rgba(23,85,161,0)" size="default" color="#fff" type="ball-pulse" [fullScreen]="false">
          </ngx-spinner> -->


          <a id="sign-in-button">



          </a>
        </button>

        <!--
             <button class="mx-2 my-2" mat-button routerLink="/register"
             routerLinkActive="active">Register</button>
        -->
        <button class="mx-2 my-2" mat-button routerLink="/forgot-password" routerLinkActive="active">
          Forgot
          Password?
        </button>
      </div>
    </form>
  </div>
</div>
